import React, {useEffect, useState} from 'react'
import {Row, Col, Form, Button, Modal} from 'react-bootstrap'
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {updateOrder} from "../../store/redux";
import {
    getProductsRequest,
    updateOrderRequest,
    getOrderItemsRequest,
    getOrderByNumberRequest,
    getCustomerRequest,
} from "../../requests/api";

function getIdFromObj(objString) {
    var n = objString.lastIndexOf('/');
    return objString.substring(n + 1);
}

const EditOrder = (props) => {

    // Attention on passe le numéro de la commande en paramètre url et non pas l'id il ne faut pas utiliser le props.match.params.id
    const orderID = props.match.params.id;
    const [items, setItems] = useState([]);

    const [customer, setCustomer] = useState("")
    console.log(customer);
    const [order, setOrder] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customerLastname, setCustomerLastname] = useState("");
    const [customerFirstname, setCustomerFirstname] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [newBillinglastname, setNewbillinglastname] = useState("");
    const [newBillingfirstname, setNewbillingfirstname] = useState("");
    const [newBillingaddress, setNewbillingaddress] = useState("");
    const [newBillingzipcode, setNewbillingzipcode] = useState("");
    const [newBillingcity, setNewbillingcity] = useState("");
    const [newBillingphone, setNewbillingphone] = useState("");
    const [newState, setNewState] = useState("");
    const [newShippinglastname, setNewshippinglastname] = useState("");
    const [newShippingfirstname, setNewshippingfirstname] = useState("");
    const [newShippingaddress, setNewshippingaddress] = useState("");
    const [newShippingaddress2] = useState("");
    const [newShippingzipcode, setNewshippingzipcode] = useState("");
    const [newShippingcity, setNewshippingcity] = useState("");
    const [newBillingCompany, setNewBillingCompany] = useState("");
    const [newShippingCompany, setNewShippingCompany] = useState("");
    const [newKitvaisselle, setNewkitvaisselle] = useState("");
    const [newMinipoele, setNewminipoele] = useState("");
    const [newShippingcost, setNewshippingcost] = useState("");
    const [newObservations, setNewobservations] = useState("");
    const [newNoteClient, setNewNoteClient] = useState("");
    const [newAcompte, setNewacompte] = useState("");
    const [newSolde, setNewsolde] = useState("");
    const [newEmpreinte, setNewEmpreinte] = useState("");
    const [newSmsavis, setNewSmsavis] = useState("");
    const [newEvenement, setNewevenement] = useState("");
    const [newSource, setNewsource] = useState("");
    const [newMateriel, setNewmateriel] = useState("");
    const [newRetourmateriel, setNewretourmateriel] = useState("");
    const [newDateandhour, setNewdateandhour] = useState("");
    const [newExpeditionType, setExpeditionType] = useState("");
    const [newGrandTotal, setGrandTotal] = useState(0);
    const [catalogueProducts, setCatalogueProducts] = useState([]);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    let history =useHistory()

    useEffect(() => {

        const getCatalogueProducts = async () => {

            const data = await getProductsRequest();
            setCatalogueProducts(data.map((item) => ({...item, id: item.id })));
        }

        getCatalogueProducts();

    }, [])


    useEffect(() => {

        const getOrderInfos = async () => {

            // Récupération de la commande

            await getOrderByNumberRequest(orderID)
            .then(function(response) {
                const order = response;

                setOrder({...order, id: order.id});

                // Infos de facturation
                setNewbillinglastname(order['billing_last_name']);
                setNewbillingfirstname(order['billing_first_name']);
                setNewbillingaddress(order['billing_address']);
                setNewbillingzipcode(order['billing_postcode']);
                setNewbillingcity(order['billing_city']);
                setNewBillingCompany(order['billing_company']);
                setNewbillingphone(order['billing_phone']);

                // Infos de livraison
                setNewshippinglastname(order['shipping_last_name']);
                setNewshippingfirstname(order['shipping_first_name']);
                setNewShippingCompany(order['shipping_company']);
                setNewshippingaddress(order['shipping_address']);
                setNewshippingzipcode(order['shipping_postcode']);
                setNewshippingcity(order['shipping_city']);

                // Autres infos
                setNewState(order['status']);
                setNewkitvaisselle(order['meta_myam_Nb_Kit_Vaisselles'])
                setNewminipoele(order['meta_myam_Nb_Mini_Poele']);
                setNewshippingcost(order['meta_myam_Forfait_Livraison']);
                setNewobservations(order['meta_myam_Observations_Commande']);
                setNewacompte(order['meta_myam_Acompte']);
                setNewsolde(order['meta_myam_Solde']);
                setNewevenement(order['meta_occasion']);
                setNewsource(order['meta_vous_nous_avez_connu_comment']);
                setNewretourmateriel(order['meta_myam_Retour_Materiel']);
                setNewmateriel(order['meta_myam_Materiel']);
                setGrandTotal(order['grand_total']);
                setExpeditionType(order['meta_myam_Type_Expedition']);
                setNewEmpreinte(order['empreinte']);
                setNewSmsavis(order['smsavisclient']);
                setNewdateandhour(String(order['order_delivery_date']).substr(0,16));
                setNewNoteClient(order['customer_message']);

                // Items de la commande
                getOrderItemsRequest(order.id)
                    .then((response) => {
                        setItems(response.map((item) => ({...item, id: item.id})));
                    });

                // Client
                getCustomerRequest(order.customer)
                    .then((response) => {
                        setCustomer({...response, id: response.id});
                        setCustomerEmail(response['email']);
                        setCustomerFirstname(response['firstname']);
                        setCustomerLastname(response['lastname']);
                        setCustomerId(response['id']);
                    });
            });

        }

        getOrderInfos();

    }, [orderID])

    const addItem = async () => {
        setItems([...items, { id: null, product:null, order:null, name: '', quantity:0, unitPrice:0, total:0}]);
    }

    let handleChange = (i, e) => {
        let newItemValues = [...items];
        newItemValues[i][e.target.name] = e.target.value;
        let unitPrice = newItemValues[i]['unitPrice'] ? newItemValues[i]['unitPrice'].toString().replace(',','.') : 0;
        newItemValues[i]['total'] = (parseInt(newItemValues[i]['quantity']) * parseFloat(unitPrice)).toFixed(2);
        setItems(newItemValues);
        calculGrandTotal();
    }

    let handleProductChange = (i, e) => {
        let newItemValues = [...items];

        newItemValues[i]['product'] = e.target.value;

        var selectedIndex = e.target.selectedIndex;
        newItemValues[i]['name'] = e.target[selectedIndex].text;

        setItems(newItemValues);
        calculGrandTotal();
    }

    let calculGrandTotal = () => {

        var grandtotal = 0;
        items.forEach((element, index)=>{
            grandtotal = parseFloat(grandtotal) + ( parseFloat(String(element.unitPrice).replace(',','.')) * parseInt(element.quantity) );
        })

        setGrandTotal(parseFloat(grandtotal).toFixed(2));
        setNewsolde(parseFloat(grandtotal).toFixed(2));
    }

    const cancelOrder = () => {

        history.push('/orders');

    }

    function getNbParts(searchStr) {

        let nbParts = 0;
        items.forEach((item, index)=>{
            if(item.name.toLowerCase().indexOf(searchStr) !== -1) {
                nbParts = parseInt(nbParts) + parseInt(item.quantity);
            }
        })
        return nbParts;

    }

    const saveOrder = async () => {

        const orderObject = {
            order: {
                id: order.id,
                customerFirstName: customerFirstname ? customerFirstname : "",
                customerLastName: customerLastname ? customerLastname : "",
                customerEmail: customerEmail ? customerEmail : "",
                billingAddress: newBillingaddress ? newBillingaddress : "",
                billingCity: newBillingcity ? newBillingcity : "",
                billingCompany: newBillingCompany ? newBillingCompany : "",
                billingFirstName: newBillingfirstname ? newBillingfirstname : "",
                billingLastName: newBillinglastname ? newBillinglastname : "",
                billingPostcode: newBillingzipcode ? newBillingzipcode : "",
                billingPhone: newBillingphone ? newBillingphone : "",
                date_modified: new Date().toISOString(),
                grandTotal: parseFloat(newGrandTotal),
                metaDateDeLivraison: newDateandhour,
                metaMyamAcompte: parseFloat(newAcompte),
                metaMyamDateLivraisonOuRetrait: newDateandhour,
                metaMyamDateRetrait: newDateandhour,
                metaMyamForfaitLivraison: parseFloat(newShippingcost),
                metaMyamMateriel: newMateriel ? newMateriel : "",
                metaMyamMontantTotal: parseFloat(newGrandTotal),
                metaMyamNbKitVaisselles: parseInt(newKitvaisselle),
                metaMyamNbMiniPoele: parseInt(newMinipoele),
                metaMyamNbPartRoyales: getNbParts('oyale'),
                metaMyamNbPartSansCrustaces: getNbParts('lla sans crust'),
                metaMyamNbPartVegetaliennes: getNbParts('gane'),
                metaMyamNbPartVegetariennes: getNbParts('tarienne'),
                metaMyamNbPartFetes: getNbParts('ciale f'),
                metaMyamObservationsCommande: newObservations ? newObservations : "",
                metaMyamRemise: parseFloat(0),
                metaMyamRetourMateriel: newRetourmateriel ? newRetourmateriel : "",
                metaMyamSolde: parseFloat(newSolde),
                metaMyamTypeEvenement: newEvenement ? newEvenement : "",
                metaMyamTypeExpedition: newExpeditionType ? newExpeditionType : "",
                metaOccasion: newEvenement ? newEvenement : "",
                metaOrderDeliveryTimestamp: newDateandhour ? newDateandhour : "",
                orderDeliveryDate: newDateandhour ? newDateandhour : "",
                metaVousNousAvezConnuComment: newSource ? newSource : "",
                shippingAddress: newShippingaddress ? newShippingaddress : "",
                shippingAddress2: newShippingaddress2 ? newShippingaddress2 : "",
                shippingCity: newShippingcity ? newShippingcity : "",
                shippingCompany: newShippingCompany ? newShippingCompany : "",
                shippingCountry: null,
                shippingFirstName: newShippingfirstname ? newShippingfirstname : "",
                shippingLastName: newShippinglastname ? newShippinglastname : "",
                shippingPostcode: newShippingzipcode ? newShippingzipcode : "",
                shippingState: null,
                shippingTotal: parseFloat(newShippingcost),
                status: newState,
                empreinte: newEmpreinte ? newEmpreinte : "",
                smsavisclient: newSmsavis ? newSmsavis : "",
                customerMessage: newNoteClient ? newNoteClient : "",
            },
            'order_items': []
        };

        // Articles de la commande

        if(items.length > 0 && items[0].product !== null && items[0].quantity !== 0 && items[0].total !== "NaN") {
            items.forEach((element, index) => {

                if(typeof(element.unitPrice) === "string") {
                    element.unitPrice = element.unitPrice.replace(',', '.');
                }

                if(typeof(element.total) === "string") {
                    element.total = element.total.replace(',', '.');
                }

                let newItem = {
                    "name": element.name,
                    "quantity": parseInt(element.quantity),
                    "total": parseFloat(element.total),
                    "productId": getIdFromObj(element.product),
                    "unitPrice": parseFloat(element.unitPrice)
                }

                orderObject['order_items'].push(newItem);
            })
        }
        else {
            setShowError(true);
            setErrorMessage('Veuillez séléctionner au moins un article');
            return false;
        }

        await updateOrderRequest(orderObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {
                    // TODO : mise à jour de la commande dans redux mais pour cela il faut que redux connaissance l'ensemble du dataset de commandes
                    dispatch(updateOrder(response));

                    // SUCCESS
                    history.push('/orders/dashboard/' + response.number);
                }

            });

    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Client</h4>
                                    {customerId !== null &&
                                        <span>{customerId}</span>
                                    }
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customerlastname">Nom</Form.Label>
                                                <Form.Control type="text" value={customerLastname} id="customerlastname" onChange={(event) => {
                                                    setCustomerLastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customerfirstname">Prénom</Form.Label>
                                                <Form.Control type="text" value={customerFirstname}  id="customerfirstname" onChange={(event) => {
                                                    setCustomerFirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customeremail">Adresse email</Form.Label>
                                                <Form.Control type="email" value={customerEmail} id="customeremail" onChange={(event) => {
                                                    setCustomerEmail(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Facturation</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billinglastname">Nom</Form.Label>
                                                <Form.Control type="text" value={newBillinglastname} id="billinglastname" onChange={(event) => {
                                                    setNewbillinglastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingfirstname">Prénom</Form.Label>
                                                <Form.Control type="text" value={newBillingfirstname} id="billingfirstname" onChange={(event) => {
                                                    setNewbillingfirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingcompany">Entreprise</Form.Label>
                                                <Form.Control type="text" value={newBillingCompany} id="billingcompany" onChange={(event) => {
                                                    setNewBillingCompany(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingaddress">Adresse</Form.Label>
                                                <Form.Control type="text" value={newBillingaddress} id="billingaddress" onChange={(event) => {
                                                    setNewbillingaddress(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingzipcode">Code postal</Form.Label>
                                                <Form.Control type="text" value={newBillingzipcode} id="billingzipcode" onChange={(event) => {
                                                    setNewbillingzipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingcity">Ville</Form.Label>
                                                <Form.Control type="text" value={newBillingcity} id="billingcity" onChange={(event) => {
                                                    setNewbillingcity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingphone">Téléphone</Form.Label>
                                                <Form.Control type="text" value={newBillingphone} id="billingphone" onChange={(event) => {
                                                    setNewbillingphone(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Expedition</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Livraison"  type="radio" name="expedition" id="expedition1" checked={newExpeditionType === "Livraison"} onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition1">
                                                            Livraison à domicile
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Retrait" type="radio" name="expedition" id="expedition2" checked={newExpeditionType === "Retrait"} onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition2">
                                                            Retrait magasin
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Cuisson-sur-place" type="radio" name="expedition" id="expedition3" checked={newExpeditionType === "Cuisson-sur-place"} onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition3">
                                                            Cuisson sur place
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"20px"}}>
                                    <Col>
                                        <Form.Group className="form-group">
                                            <Form.Label  htmlFor="dateandhour">Date et heure</Form.Label>
                                            <Form.Control type="datetime-local" value={newDateandhour} id="dateandhour" onChange={(event) => {
                                                setNewdateandhour(event.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Adresse livraison</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippinglastname">Nom</Form.Label>
                                                <Form.Control type="text"  id="shippinglastname" value={newShippinglastname} onChange={(event) => {
                                                    setNewshippinglastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingfirstname">Prénom</Form.Label>
                                                <Form.Control type="text"  id="shippingfirstname" value={newShippingfirstname} onChange={(event) => {
                                                    setNewshippingfirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcompany">Entreprise</Form.Label>
                                                <Form.Control type="text"  id="shippingcompany" value={newShippingCompany} onChange={(event) => {
                                                    setNewShippingCompany(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingaddress">Adresse</Form.Label>
                                                <Form.Control type="text"  id="shippingaddress" value={newShippingaddress} onChange={(event) => {
                                                    setNewshippingaddress(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingzipcode">Code postal</Form.Label>
                                                <Form.Control type="text"  id="shippingzipcode" value={newShippingzipcode} onChange={(event) => {
                                                    setNewshippingzipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcity">Ville</Form.Label>
                                                <Form.Control type="text"  id="shippingcity" value={newShippingcity} onChange={(event) => {
                                                    setNewshippingcity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Articles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form id="orderitems">
                                    {items.map((element,index) => (
                                        <Row key={"item" + index}>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="name">Designation</Form.Label>
                                                    <select className="form-select" id="id" name="id" onChange={e => handleProductChange(index, e)}>
                                                        <option key={""} value="0">Sélctionner un produit</option>
                                                    {catalogueProducts.map((product,productindex) => (
                                                        <option selected={element.product === product['@id']} key={"product" + index + 'p' + productindex} value={product['@id']}>{product.title}</option>
                                                    ))}
                                                    </select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="quantity">Quantité</Form.Label>
                                                    <Form.Control type="integer"  id="quantity" name="quantity" value={element.quantity || ""} onChange={e => handleChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="unitPrice">Prix unitaire TTC</Form.Label>
                                                    <Form.Control type="text"  id="unitPrice" name="unitPrice" value={element.unitPrice || ""} onChange={e => handleChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    ))}
                                </Form>
                                <Button type="button" variant="light" onClick={() => addItem()}>Ajouter un autre article</Button>
                                <h3 style={{marginTop:"30px"}}>Total TTC de la commande : <span>{newGrandTotal} €</span></h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="status">Statut</Form.Label>
                                                <select className="form-select" id="status" onChange={(event) => {
                                                    setNewState(event.target.value);
                                                }}
                                                >
                                                    <option>Séléctionner un statut</option>
                                                    <option value="processing" selected={newState === "processing"}>En cours</option>
                                                    <option value="completed" selected={newState === "completed"}>Terminée</option>
                                                    <option value="cancelled" selected={newState === "cancelled"}>Annulée</option>
                                                    <option value="refunded" selected={newState === "refunded"}>Remboursée</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Observations</Form.Label>
                                                <Form.Control type="text"  id="observations" value={newObservations} onChange={(event) => {
                                                    setNewobservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcost">Frais de livraison</Form.Label>
                                                <Form.Control type="text"  id="shippingcost" value={newShippingcost} onChange={(event) => {
                                                    setNewshippingcost(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="acompte">Acompte</Form.Label>
                                                <Form.Control type="text"  id="acompte" value={newAcompte} onChange={(event) => {
                                                    setNewacompte(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="solde">Solde</Form.Label>
                                                <Form.Control type="text" id="solde" value={newSolde} onChange={(event) => {
                                                    setNewsolde(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="solde">Empreinte</Form.Label>
                                                <Form.Control type="text" id="empreinte" value={newEmpreinte} onChange={(event) => {
                                                    setNewEmpreinte(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="kitvaisselle">Nb kit vaisselle</Form.Label>
                                                <Form.Control type="number" id="kitvaisselle" value={newKitvaisselle} onChange={(event) => {
                                                    setNewkitvaisselle(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="minipoele">Nb mini poêle</Form.Label>
                                                <Form.Control type="number" id="minipoele" value={newMinipoele} onChange={(event) => {
                                                    setNewminipoele(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="evenement">Occasion/Evénement</Form.Label>
                                                <Form.Control type="text" id="evenement" value={newEvenement} onChange={(event) => {
                                                    setNewevenement(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="source">Source</Form.Label>
                                                <Form.Control type="text"  id="source" value={newSource} onChange={(event) => {
                                                    setNewsource(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="materiel">Matériel</Form.Label>
                                                <Form.Control type="text"  id="materiel" value={newMateriel} onChange={(event) => {
                                                    setNewmateriel(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="retourmateriel">Retour matériel</Form.Label>
                                                <Form.Control type="text"  id="retourmateriel" value={newRetourmateriel} onChange={(event) => {
                                                    setNewretourmateriel(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={"3"}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="smsavis">SMS avis client</Form.Label>
                                                <Form.Control type="text" id="smsavis" value={newSmsavis} onChange={(event) => {
                                                    setNewSmsavis(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={"9"}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="noteclient">Note client</Form.Label>
                                                <Form.Control type="text" id="noteclient" value={newNoteClient} onChange={(event) => {
                                                    setNewNoteClient(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveOrder}>Enregistrer</Button>
                    </Col>
                </Row>
            </div>

            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de la commande ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition de la commande
                    </Button>
                    <Button variant="primary" onClick={cancelOrder}>
                        Je souhaite abandonner la commande en cours
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditOrder
