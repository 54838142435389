import React, {useEffect} from 'react'

//SimpleRouter 
import SimpleRouter from '../../router/simple-router'


const Simple = (props) => {
    useEffect(() => {

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        if(colorcustomizerMode===null){
            //props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode );
        }
        else{
            //props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

})

    return (
        <>
          <div id="loading">
          </div>
          <div className="wrapper">
            <SimpleRouter />
          </div>          
        </>
    )
}

export default Simple
