import React, {useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {  dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useDispatch, useSelector} from "react-redux";
import { getCustomersRequest } from "../../requests/api";
import {setCustomers} from "../../store/redux";

const columns = [{
    dataField: 'id',
    text: '#',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'firstname',
    text: 'Nom',
    sort: true,
    filter: textFilter()
},{
    dataField: 'lastname',
    text: 'Prénom',
    sort: true,
    filter: textFilter()
},{
    dataField: 'createdAt', // date_created todo timeConverter
    text: 'Date ajout',
    sort: true,
    formatter: dateFormatter,
    filter: dateFilter({
        withoutEmptyComparatorOption: true,
        comparators: ["="]
    })
},{
    dataField: 'orders',
    text: 'Nb commandes',
    sort: true,
    formatter: formatNbOrders,
}];

const defaultSorted = [{
    dataField: 'lastname',
    order: 'asc'
}];


function dateConverter(dateString, context) {

    if(dateString === null || dateString === undefined) {
        return;
    }

    // Construction du date String

    let formattedFullDateTimeString = dateString;

    let formattedDateString = dateString.substr(0,10);

    formattedFullDateTimeString = new Date(formattedDateString);

    return new Date(formattedFullDateTimeString).toLocaleDateString('fr-FR');
}

function linkFormatter(cell, row) {
    return (
        <Link to={'/customers/dashboard/' + cell}>{cell}</Link>
    );
}

function formatNbOrders(cell, row) {
    return row.orders.length;
}

function dateFormatter(cell, row) {
    return dateConverter(cell, row);
}

const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='id'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bordered={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            noDataIndication="Aucun client ou problème de chargement des données"
        />
    </div>
);

const getCustomers = async (customers) => {
    if(!customers.length) {
        const data = getCustomersRequest();
        return data;
    }
    else {
        console.log('loading customers from redux');
        return null;
    }
}

const CustomerList = () => {

    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customers);

    useEffect(() => {

        getCustomers(customers)
            .then(function(data) {
                if(data !== null) {
                    dispatch(setCustomers(data.map((customer) => ({...customer, id: customer.id}))));
                }
            });

    }, [customers, dispatch])

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Clients</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={customers}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default CustomerList
