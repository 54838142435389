import React, {useEffect, useState} from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import {getCustomerRequest, getCustomerOrdersRequest} from '../../requests/api';
import {Link} from "react-router-dom";

function dateConverter(dateString) {

    if(dateString) {

        // Fixe pour enlever la convertion UTC
        dateString = dateString.substr(0, 19);

        if (dateString === null || dateString === undefined) {
            return;
        }

        return new Date(dateString).toLocaleDateString('fr-FR');

    }

    return false;
}


function getOrderStatusTag(status) {

    var bg = 'primary';
    var text = '';

    switch (status) {
        case 'on-hold':
        case 'wc-on-hold':
            bg = 'warning';
            text = 'A valider';
            break;
        case 'processing':
        case 'wc-processing':
            bg = 'success';
            text = 'En cours';
            break;
        case 'completed':
        case 'wc-completed':
            bg = 'info';
            text = 'Terminée';
            break;
        case 'cancelled':
        case 'wc-cancelled':
            bg = 'danger';
            text = 'Annulée';
            break;
        case 'wc-refunded':
            bg = 'danger';
            text = 'Remboursée';
            break;
        default:
            bg = 'dark';
            text = status;

    }

    return <h5><Badge pill bg={bg}>{text}</Badge></h5>
}

const CustomerDashboard = (props) => {

    const customerID = props.match.params.id;
    const [customer, setCustomer] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {

        const getCustomerInfos = async () => {

            // Commande

            await getCustomerRequest(customerID)
                .then(function(response) {
                    const customer = response;

                    setCustomer({...customer, id: customer.id});

                    // Items de la commande
                    getCustomerOrdersRequest(customer.id)
                        .then((response) => {
                            setOrders(response.map((item) => ({...item, id: item.id})));
                        });
            });

        }

        getCustomerInfos();

    }, [customerID])

    return (
        <>
            <div className="bd-example">
                <Card className="mb-0">
                    <Card.Body>
                        <div className="bd-example">
                            <Row className="orderHeaderInfos">
                                <Col className="col-5">
                                    <h2 className="card-title">Client #{customer.id}</h2>
                                    <h3 className="card-title">{customer.firstname} {customer.lastname}</h3>
                                    <h5 className="card-title">{customer.email}</h5>
                                    <p className="card-text">Enregistré le {dateConverter(customer['createdAt'])}</p>
                                </Col>
                                <Col>
                                    <Card className="mb-0">
                                        <Card.Header>
                                            Adresse
                                        </Card.Header>
                                        <Card.Body>
                                            {customer.company &&
                                                <h6 className="card-title">Société {customer.company}</h6>
                                            }
                                            <h6 className="card-title">{customer.address}</h6>
                                            <h6 className="card-title">{customer.postcode} {customer.city}</h6>
                                            <h6 className="card-title">{customer.phone}</h6>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <br />
                        <h5 className="card-title">Commandes ({orders.length})</h5>
                        <div className="bd-example" style={{marginTop:"20px"}}>
                            <Row>
                                <div className="table-responsive">
                                    <table id="datatable" className="table" data-toggle="data-table">
                                        <thead>
                                        <tr>
                                            <th width="50%">Commande</th>
                                            <th>Date</th>
                                            <th>Quantité d'articles</th>
                                            <th>Montant total (TTC)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            orders.map((order, index) => (
                                                <tr>
                                                    <td>
                                                        <Link to={'/orders/dashboard/' + order.number}>Commande #{order.number}</Link>
                                                    </td>
                                                    <td>{dateConverter(order['createdAt'])}</td>
                                                    <td>{getOrderStatusTag(order.status)}</td>
                                                    <td>{order['grand_total']} €</td>
                                                </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default CustomerDashboard
