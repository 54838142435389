import React, {useEffect} from 'react'
import Index from '../views/dashboard/index'
import {Switch, Route} from 'react-router-dom'

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";
//Special Pages
import OrderList from '../views/orders/index';
import CustomerList from '../views/customers/index';
import MobileOrderList from '../views/orders/mobile';
import OrderDashboard from '../views/orders/dashboard';
import CustomerDashboard from '../views/customers/dashboard';
import EditOrder from '../views/orders/edit';
import AddOrder from '../views/orders/add';
import Calender from '../views/dashboard/special-pages/calender';
import {setOrders} from "../store/redux";
import {useDispatch, useSelector} from "react-redux";
import { getOrdersRequest } from '../requests/api';

const getOrders = async (orders) => {

    if(!orders.length) {
        const data = getOrdersRequest();
        return data;
    }
    else {
        console.log('loading from redux');
        return null;
    }

}

const DefaultRouter = () => {

    const dispatch = useDispatch();
    const orders = useSelector((state) => state.orders.orders);

    useEffect(() => {

        getOrders(orders)
            .then(function(data) {
                if(data !== null) {
                    dispatch(setOrders(data.map((order) => ({...order, id: order.id}))));
                }
            });

    }, [orders, dispatch])

    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    {/* home dashboard */}
                    <Route path="/" exact component={Index}/>
                    {/* commandes */}
                    <Route path="/orders" exact component={OrderList}/>
                    <Route path="/orders/dashboard/:id" exact component={OrderDashboard}/>
                    <Route path="/orders/edit/:id" exact component={EditOrder}/>
                    <Route path="/orders/new" exact component={AddOrder}/>
                    {/* mobile */}
                    <Route path="/mobile" exact component={MobileOrderList}/>
                    {/* planning */}
                    <Route path="/planning" exact component={Calender}/>
                    {/* clients */}
                    <Route path="/customers" exact component={CustomerList}/>
                    <Route path="/customers/dashboard/:id" exact component={CustomerDashboard}/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
