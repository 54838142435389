import React from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useSelector} from "react-redux";

const stateOptions = {
    'pending': 'En attente de paiement',
    'on-hold': 'A valider',
    'processing': 'En cours',
    'completed': 'Terminée',
    'refunded': 'Remboursée',
    'cancelled': 'Annulée',
    'failed': 'Echouée'
};

const typeOptions = {
    'Retrait': 'Retrait',
    'Livraison': 'Livraison',
    'Cuisson-sur-place': 'Cuisson sur place'
};

const columns = [{
    dataField: 'number',
    text: '#',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'billing_last_name',
    text: 'Nom',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Type_Expedition',
    text: 'Type',
    sort: true,
    formatter: formatOrderType,
    filter: selectFilter({
        options: typeOptions
    })
},{
    dataField: 'order_delivery_date', // date_created todo timeConverter
    text: 'Date',
    sort: true,
    formatter: dateFormatter,
    filter: dateFilter({
        withoutEmptyComparatorOption: true,
        comparators: ["="]
    })
},{
    dataField: 'status',
    text: 'Statut',
    sort: true,
    formatter: formatStatus,
    filter: selectFilter({
        options: stateOptions
    })
},{
    dataField: 'billing_company',
    text: 'Entreprise',
    sort: true,
    filter: textFilter()
},{
    dataField: 'billing_phone',
    text: 'Tél',
    sort: true,
    filter: textFilter()
},{
    dataField: 'shipping_city',
    text: 'Livraison',
    sort: true,
    filter: textFilter(),
    formatter: formatFullAddress
},{
    dataField: 'customer_email',
    text: 'Email',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Nb_Part_Royales',
    text: 'Part',
    formatter: formatNbParts
},{
    dataField: 'meta_myam_Nb_Kit_Vaisselles',
    text: 'Kit',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Nb_Mini_Poele',
    text: 'Mini',
    sort: true,
    filter: textFilter()
},{
    dataField: 'shipping_total',
    text: 'FDL',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam-Remise',
    text: 'Remise',
    sort: true,
    filter: textFilter()
},{
    dataField: 'customer_message',
    text: 'Note client',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Observations_Commande',
    text: 'Observatons',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Acompte',
    text: 'Acompte',
    sort: true,
    filter: textFilter(),
    formatter: moneyFormatter
},{
    dataField: 'meta_myam_Solde',
    text: 'Solde',
    sort: true,
    filter: textFilter(),
    formatter: moneyFormatter
},{
    dataField: 'grand_total',
    text: 'Total',
    sort: true,
    filter: textFilter(),
    formatter: moneyFormatter
},{
    dataField: 'meta_myam_Materiel',
    text: 'Matériel.',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_myam_Retour_Materiel',
    text: 'Retour mat.',
    sort: true,
    filter: textFilter()
},{
    dataField: 'empreinte',
    text: 'Empreinte',
    sort: true,
    filter: textFilter()
},{
    dataField: 'meta_occasion',
    text: 'Occasion',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'meta_vous_nous_avez_connu_comment',
    text: 'Source',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'smsavisclient',
    text: 'SMS Avis client',
    sort: true,
    filter: textFilter()
}];

const defaultSorted = [{
    dataField: 'order_delivery_date',
    order: 'desc'
}];

const nbPartsFields = [{
    "label": "Royale",
    "rowKey": "meta_myam_Nb_Part_Royales",
},{
    "label":"Sans Crustaces",
    "rowKey": "meta_myam_Nb_Part_Sans_Crustaces",
},{
    "label":"Vegetariennes",
    "rowKey": "meta_myam_Nb_Part_Vegetariennes",
},{
    "label":"Vegetaliennes",
    "rowKey": "meta_myam_Nb_Part_Vegetaliennes",
},{
    "label":"Fêtes",
    "rowKey": "meta_myam_Nb_Part_Fetes",
}]

function formatNbParts(cell, row) {
    return (
        <div className={"nbpartsWrapper"}>
            {nbPartsFields.map((element,index) => (
                <span className={'nb' + row[element.rowKey]} title={element.label}>{row[element.rowKey] ? row[element.rowKey] : 0}</span>
            ))}
        </div>
    );
}
function formatOrderType(cell) {

    let bg = 'light';

    switch(cell) {
        case 'Retrait':
            bg = "warning";
            break;
        case 'Livraison':
            bg = "primary";
            break;
        case 'Cuisson-sur-place':
            bg = "purple";
            break;
        default:
            bg = "light";
    }

    return (
        <h5><Badge pill bg={bg}>{cell}</Badge></h5>
    )
}

function formatStatus(cell) {
    let bg = 'light';

    switch(cell) {
        case 'pending':
            bg = "warning";
            break;
        case 'on-hold':
            bg = "warning";
            break;
        case 'processing':
            bg = "success";
            break;
        case 'refunded':
            bg = "warning";
            break;
        case 'cancelled':
            bg = "warning";
            break;
        case 'failed':
            bg = "warning";
            break;
        case 'completed':
            bg = "info";
            break;
        default:
            bg = "light";
    }

    return (
        <h5><Badge pill bg={bg}>{stateOptions[cell]}</Badge></h5>
    )
}

function formatFullAddress(cell, row) {
    return (
        <div>
            <span>{row.shipping_address_1} {row.shipping_address_2} </span>
            <p>{row.shipping_postcode} {row.shipping_city}</p>
        </div>
    )
}
function moneyFormatter(value) {
    if(value !== '' && value !== " " && value !== undefined) {
        return (
            <span>{value} €</span>
        );
    }
}
function linkFormatter(cell, row) {
    return (
        <Link to={'/orders/dashboard/' + cell}>{cell}</Link>
    );
}

function dateFormatter(cell, row) {
    return dateConverter(cell, row);
}

function dateConverter(dateString, context) {

    if(dateString === null || dateString === undefined) {
        return;
    }

    // Construction du date String

    let formattedFullDateTimeString = dateString;

    let formattedDateString = dateString.substr(0,10);//.toLocaleDateString('fr-FR');
    let formattedTimeString = "00:00";

    if(context['meta_myam_Date_Livraison_Ou_Retrait'] !== "") {
        if(context['meta_myam_Date_Livraison_Ou_Retrait'].includes('à')) {
            formattedTimeString = String(context['meta_myam_Date_Livraison_Ou_Retrait']).substr(13,5);
        }
        else {
            formattedTimeString = String(context['meta_myam_Date_Livraison_Ou_Retrait']).substr(11,5);
        }
    }

    formattedFullDateTimeString = new Date(formattedDateString + 'T' + formattedTimeString);

    return new Date(formattedFullDateTimeString).toLocaleDateString('fr-FR') + ' à ' + new Date(formattedFullDateTimeString).getHours() + 'h' + String(new Date(formattedFullDateTimeString).getMinutes()).padStart(2, "0");;
}

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === "processing") {
        style.backgroundColor = '#dcecdc';
    } else if (row.status === "completed") {
        style.backgroundColor = '#b7b7b7';
    } else {
        style.backgroundColor = '#fdabab';
    }

    return style;
}

const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='number'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bordered={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            rowStyle={ rowStyle }
            //pagination={ paginationFactory() }
            noDataIndication="Aucune commande ou problème de chargement des données"
        />
    </div>
);

const OrderList = () => {

    const orders = useSelector((state) => state.orders.orders);

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Commandes</h4>
                            <Link to="/orders/new" className="btn btn-primary -pull-right">Ajouter une commande</Link>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={orders}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default OrderList
