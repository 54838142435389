import React from 'react'

//router
import { Switch,Route } from 'react-router'
//layoutpages
import Default from '../layouts/dashboard/default'
import Simple from '../layouts/dashboard/simple'
import {useHistory} from "react-router-dom";

const IndexRouters = () => {

    let history = useHistory()

    const loggedUser = window.localStorage.getItem("loggedUser");
    //const dispatch = useDispatch();

    if(loggedUser === null) {
        history.push('/auth/sign-in')
    }

    /*const logout = async () => {
        // SIGN OUT TODO

        await signOut(auth)
            .then(function() {
                dispatch(clearLoggedUser());
                //history.push('/auth/sign-in');
            })


    }*/

    return (
        <>
            {loggedUser !== null &&
                <Switch>
                    <Route exact path="/" component={Default}></Route>
                    <Route  path="/orders" component={Default}></Route>
                    <Route  path="/mobile" component={Default}></Route>
                    <Route  path="/planning" component={Default}></Route>
                    <Route  path="/customers" component={Default}></Route>
                </Switch>
            }
            <Switch>
                <Route  path="/auth" component={Simple}></Route>
            </Switch>
        </>
    )
}

export default IndexRouters
