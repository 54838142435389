import React, {useState} from 'react'
import {Row,Col,Image,Form,Button,Alert} from 'react-bootstrap'
import {loginRequest} from '../../../requests/api';
import Card from '../../../components/Card'
import { setLoggedUser } from "../../../store/redux";
import logoImage from '../../../assets/images/logo/logomanager.png';
// img
import auth1 from     '../../../assets/images/auth/01.png'

import {useDispatch} from "react-redux"
import Logo from "../../../components/partials/components/logo";

const SignIn = () => {

   const [loginEmail, setLoginEmail] = useState("");
   const [loginPassword, setLoginPassword] = useState("");
   const [authError, setAuthError] = useState("");

   const dispatch = useDispatch();

   const login = async () => {

      setAuthError("");

      await loginRequest({'email': loginEmail, 'password': loginPassword})
          .then((data) => {

             // TODO : comment faire cela directement dans l'objet qui gère la requête?
             dispatch(setLoggedUser(loginEmail));
             window.location.href = '/';
             return false;

          })
          .catch((error) => {

             var errorString = "Erreur d'authentification";

             if(error.code) {
                switch(error.code) {
                   case 'auth/wrong-password':
                   case 'auth/user-not-found':
                   default:
                      errorString = "Identifiants incorrect";
                      break;
                }
             }
             setAuthError(errorString);
          })
      }


   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                           <Card.Header>
                                 <Logo color={true} />
                                 <h4 style={{"flex": 1,"marginTop": "30px"}} className="logo-title ms-3">Myam Paella Manager</h4>
                           </Card.Header>
                           <Card.Body>
                              <h2 className="mb-2 text-center">Authentification</h2>
                              {authError &&
                               <div>
                                 <Alert variant="danger d-flex align-items-center" role="alert">
                                    <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                       <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                    </svg>
                                    <div>
                                       {authError}
                                    </div>
                                 </Alert>
                               </div>
                              }
                              <Form>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="email" className="">Email</Form.Label>
                                          <Form.Control type="email" className="" id="email" aria-describedby="email" placeholder=" "
                                          onChange={(event) => {
                                             setLoginEmail(event.target.value);
                                          }} />
                                       </Form.Group >
                                    </Col>
                                    <Col lg="12" className="">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="password" className="">Mot de passe</Form.Label>
                                          <Form.Control type="password" className="" id="password" aria-describedby="password" placeholder=" "
                                            onChange={(event) => {
                                               setLoginPassword(event.target.value);
                                            }} />
                                       </Form.Group>
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <Button  onClick={login} type="button" variant="btn btn-primary">Connexion</Button>
                                 </div>
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <div className="sign-bg">
                     <Image src={logoImage} style={{width:"355px",opacity:"0.1","-webkit-filter":"grayscale(100%)",filter:"grayscale(100%)"}} className={"img-fluid mb-4"} alt=""/>
                  </div>
               </Col>
               <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images"/>
               </Col>
            </Row>
         </section>
        </>
    )
}

export default SignIn
