import React, {useEffect, useState} from 'react'
import {Row, Col, ListGroup, ListGroupItem, Badge, Button} from 'react-bootstrap'
import Card from '../../components/Card'
import {useDispatch} from "react-redux";
import {removeOrder} from "../../store/redux";
import DatePicker, { registerLocale }  from "react-datepicker";
import fr from "date-fns/locale/fr";
import { useHistory  } from "react-router-dom";
import {getOrdersByDateRequest} from "../../requests/api";

registerLocale("fr",fr);

const nbPartsFields = [{
    "label": "Royale",
    "rowKey": "meta_myam_Nb_Part_Royales",
},{
    "label":"Sans Crustaces",
    "rowKey": "meta_myam_Nb_Part_Sans_Crustaces",
},{
    "label":"Vegetariennes",
    "rowKey": "meta_myam_Nb_Part_Vegetariennes",
},{
    "label":"Vegetaliennes",
    "rowKey": "meta_myam_Nb_Part_Vegetaliennes",
}
]

function formatNbParts(order) {
    return (
        <div className={"nbpartsWrapper"}>
            {nbPartsFields.map((element,index) => (
                <span className={'nb' + order[element.rowKey]} title={element.label}>{order[element.rowKey] ? order[element.rowKey] : 0}</span>
            ))}
        </div>
    );
}

function getExpeditionType(order) {
    return order['meta_myam_Type_Expedition'];
}

function getOrderStatusTag(status) {

    var bg = 'primary';
    var text = '';

    switch (status) {
        case 'on-hold':
        case 'wc-on-hold':
            bg = 'warning';
            text = 'A valider';
            break;
        case 'processing':
        case 'wc-processing':
            bg = 'success';
            text = 'En cours';
            break;
        case 'completed':
        case 'wc-completed':
            bg = 'info';
            text = 'Terminée';
            break;
        case 'cancelled':
        case 'wc-cancelled':
            bg = 'danger';
            text = 'Annulée';
            break;
        case 'wc-refunded':
            bg = 'danger';
            text = 'Remboursée';
            break;
        default:
            bg = 'dark';
            text = status;

    }

    return <h5><Badge pill bg={bg}>{text}</Badge></h5>
}
function formatPlanningDate(date) {
    const [year, month, day] = date.split('-');

    return day + '/' + month + '/' + year;
}

function getOrderHour(order) {
    if(order['meta_myam_Date_Livraison_Ou_Retrait'] !== "") {
        if(order['meta_myam_Date_Livraison_Ou_Retrait'].includes('à')) {
            return String(order['meta_myam_Date_Livraison_Ou_Retrait']).substr(13,5);
        }
        else {
            return String(order['meta_myam_Date_Livraison_Ou_Retrait']).substr(11,5);
        }
    }

    return false;
}

const getOrdersByDate = async (date) => {

    var filteredDateTime = new Date(date).toISOString();

    var filteredDate = filteredDateTime.split('T');

    var from = filteredDate[0] + 'T00:00:00';
    var to = filteredDate[0] + 'T23:59:59';

    const data = getOrdersByDateRequest(from, to);
    return data;

}

const getAddress = (orderObj) => {

    if(orderObj.shipping_address_1 || orderObj.shipping_address_2) {
        return orderObj.shipping_address_1 + " " +  orderObj.shipping_address_2;
    }
    else {
        return orderObj.billing_address;
    }
}

const getPostCode = (orderObj) => {

    if(orderObj.shipping_postcode) {
        return orderObj.shipping_postcode;
    }
    else {
        return orderObj.billing_postcode;
    }
}

const getCity = (orderObj) => {

    if(orderObj.shipping_city) {
        return orderObj.shipping_city;
    }
    else {
        return orderObj.billing_city;
    }
}


const MobileOrderList = () => {

    //const orders = useSelector((state) => state.orders.orders);
    const [orders, setOrders] = useState([]);
    const [ordersByDates,setOrderByDates] = useState([]);
    const [datesArray,setDatesArray] = useState([]);
    const [fullDatesArray,setFullDatesArray] = useState([]);
    const dispatch = useDispatch();
    const [dateFilter, setDateFilter] = useState(new Date());
    const [isReloading, setIsReloading] = useState(false);
    const history = useHistory();

    const goToOrder = (orderNumber) => {
        let path = 'orders/dashboard/' + orderNumber;
        history.push(path);
    }

    useEffect(() => {

        const tmpDatesArray = [];
        const tmpOrderByDates = [];

        orders.forEach((order) => {
            let orderDate = order['meta_myam_Date_Livraison_Ou_Retrait'];

            var [dateValues] = orderDate.split(' ');
            var [day, month, year] = dateValues.split('/');

            if(orderDate.includes('/')) {
                // date FR
                [dateValues] = orderDate.split(' ');
                [day, month, year] = dateValues.split('/');
            }
            else if(orderDate.includes('T')) {
                // date EN avec T
                [dateValues] = orderDate.split('T');
                [year, month, day] = dateValues.split('-');
            }
            else {
                // date EN avec espace
                [dateValues] = orderDate.split(' ');
                [year, month, day] = dateValues.split('-');
            }

            if(day !== "undefined" && day !== "Invalid" && month !== "undefined" && month !== "Invalid" && year !== "Invalid" && year !== "undefined") {

                const date = new Date(+year, month - 1, +day, 12, 0, 0);

                let shortDate = date.toISOString().substr(0, 10);

                if (!tmpDatesArray.includes(shortDate)) {
                    tmpDatesArray.push(shortDate);
                }

                if (tmpOrderByDates[shortDate] === undefined) {
                    tmpOrderByDates[shortDate] = [];
                }

                tmpOrderByDates[shortDate].push(order);
            }
            else {
                console.log('order id #' + order['id'] + ' is undefined date');
                // remove this from redux
                dispatch(removeOrder(order));

            }
        });

        tmpDatesArray.sort();
        tmpDatesArray.reverse();
        tmpOrderByDates.sort();
        tmpOrderByDates.reverse();

        setFullDatesArray(tmpDatesArray);
        setDatesArray(tmpDatesArray);
        setOrderByDates(tmpOrderByDates);

        if(dateFilter !== null) {
            dateFilter.setHours(12);
            var formattedDate = String(dateFilter.toISOString()).substr(0, 10);
            var newArray = [];
            newArray.push(formattedDate);
            setDatesArray(newArray);
        }

    }, [orders, dateFilter, dispatch])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        handleChangeDate(dateFilter);
    }, []);


    const reloadOrders = async(date) => {
        setIsReloading(true);
        handleChangeDate(date);
    }

    const handleChangeDate = (date) => {
        setDateFilter(date);

        getOrdersByDate(date)
            .then(function(data) {
                if(data !== null) {
                    setOrders(data.map((order) => ({...order, id: order.id})));
                    setIsReloading(false);
                }
            });


        if(date !== null) {
            date.setHours(12);
            var formattedDate = String(date.toISOString()).substr(0, 10);
            var newArray = [];
            newArray.push(formattedDate);
            setDatesArray(newArray);
        }
        else {
            setDatesArray(fullDatesArray);
        }
    };


    return (
        <>
            <Row className="ordersMobilePage">
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Commandes</h4>
                        </Card.Header>
                        <Card.Body>
                            <Row className="">
                                <Col md="10">
                                    <div className="datefilterWrapper">
                                        <DatePicker
                                            selected={dateFilter}
                                            onChange={(date) => handleChangeDate(date)}
                                            locale="fr"
                                            calendarStartDay={1}
                                            todayButton="Aujourd'hui"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Aucune date séléctionnée"
                                        />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <Button variant={"warning"} disabled={isReloading} onClick={() => reloadOrders(dateFilter)}> {isReloading ? 'Chargement…' : 'Recharger'}</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="group1-wrap">
                                        <div className="group" id="group1">
                                            <Col lg="12" className=" group__item">
                                                <div className="bd-example">
                                                    {datesArray.length && datesArray?.map((sdate, key) => (
                                                        <>
                                                            <h3 className={"dateTitle"}>{formatPlanningDate(sdate)}</h3>
                                                            <ListGroup as="ol" key={sdate} className="list-group">

                                                            {ordersByDates[sdate]?.map((item, key) => (

                                                                <ListGroupItem key={item.id} action onClick={(id) => goToOrder(item.number)} className={"list-group-item-action orderstatus-" + item.status + " delivery-" + getExpeditionType(item)}>
                                                                    <div className={"d-flex w-100 justify-content-between"}>
                                                                        <div className={"w-100"}>
                                                                            <div className="d-flex w-100 justify-content-between">
                                                                                <h4 className={"lastname"}>{item.billing_last_name} ({item.number})</h4>
                                                                            </div>
                                                                            <div className="d-flex w-100 justify-content-between">
                                                                                <h5>{getExpeditionType(item)}</h5>
                                                                                <h5 className={"orderHour"}>{getOrderHour(item)}</h5>
                                                                            </div>
                                                                            <div className="d-flex w-100 justify-content-between">
                                                                                { getOrderStatusTag(item['status']) }
                                                                            </div>
                                                                            <div className="d-flex w-100 justify-content-between">
                                                                                <h5>{item.billing_phone}</h5>
                                                                            </div>
                                                                            <span className={"address address1"}>{getAddress(item)}</span>
                                                                            <span className={"address address2"}>{getPostCode(item)} {getCity(item)}</span>
                                                                        </div>
                                                                        <div style={{"flexDirection":"column"}} className="d-flex  justify-content-between">
                                                                            {formatNbParts(item)}
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>

                                                            ))}

                                                            {ordersByDates[sdate] === undefined &&
                                                                <span>Aucune commande enregistrée pour ce jour</span>
                                                            }

                                                            </ListGroup>
                                                        </>
                                                    ))}

                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default MobileOrderList
