import React, {useEffect, useState} from 'react'
import {Row, Col, Badge, Modal, Button} from 'react-bootstrap'
import Card from '../../components/Card'
import {Link, useHistory} from "react-router-dom";
import {removeOrder} from "../../store/redux";
import {useDispatch} from "react-redux";
import {deleteOrderRequest, getOrderByNumberRequest, getOrderItemsRequest, getCustomerRequest} from '../../requests/api';

function dateConverter(dateString, add1hour = false) {

    if(dateString) {

        // Fixe pour enlever la convertion UTC
        dateString = dateString.substr(0, 19);

        if (dateString === null || dateString === undefined) {
            return;
        }

        var dateToDisplay =  new Date(dateString).toLocaleDateString('fr-FR')
            + ' à ' + String(new Date(dateString).getHours()).padStart(2, "0") + 'h' + String(new Date(dateString).getMinutes()).padStart(2, "0");

        if(add1hour) {
            dateToDisplay = dateToDisplay + ' - ' + String(new Date(dateString).getHours() + 1).padStart(2, "0") + 'h' + String(new Date(dateString).getMinutes()).padStart(2, "0");
        }

        return dateToDisplay;

    }

    return false;
}

function getObservations(order) {
    var str = '';
    if(order['meta_myam_Observations_Commande'] !== " ") {
        str += "Observations : " + order['meta_myam_Observations_Commande'];
    }

    return str;
}

function getNoteClient(order) {

    var str = '';
    if(order['customer_message'] !== " ") {
        str += "Note client : " + order['customer_message'];
    }

    return str;
}


function getOrderStatusTag(status) {

    var bg = 'primary';
    var text = '';

    switch (status) {
        case 'on-hold':
        case 'wc-on-hold':
            bg = 'warning';
            text = 'A valider';
        break;
        case 'processing':
        case 'wc-processing':
            bg = 'success';
            text = 'En cours';
        break;
        case 'completed':
        case 'wc-completed':
            bg = 'info';
            text = 'Terminée';
            break;
        case 'cancelled':
        case 'wc-cancelled':
            bg = 'danger';
            text = 'Annulée';
            break;
        case 'wc-refunded':
            bg = 'danger';
            text = 'Remboursée';
            break;
        default:
            bg = 'dark';
            text = status;

    }

    return <h3><Badge pill bg={bg}>{text}</Badge></h3>
}

const OrderDashboard = (props) => {

    const orderID = props.match.params.id;
    const [order, setOrder] = useState([]);
    const [items, setItems] = useState([]);
    const [customer, setCustomer] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    const deleteOrder = (orderObject) => {

        var orderID = orderObject.id;

        // Suppression de la commande

        deleteOrderRequest(orderID)
            .then(function() {
                // On met à jour dans redux
                dispatch(removeOrder(orderObject));
                // redirection
                history.push('/orders');
            });

    }

    useEffect(() => {

        const getOrderInfos = async () => {

            // Commande

            await getOrderByNumberRequest(orderID)
                .then(function(response) {
                    const order = response;

                    setOrder({...order, id: order.id});

                    // Items de la commande
                    getOrderItemsRequest(order.id)
                        .then((response) => {
                            setItems(response.map((item) => ({...item, id: item.id})));
                        });

                    // Client
                    getCustomerRequest(order.customer)
                        .then((response) => {
                            setCustomer({...response, id: response.id});
                        });
            });

        }

        getOrderInfos();

    }, [orderID])

    return (
        <>
            <div className="bd-example">
                <Card className="mb-0">
                    <Card.Body>
                        <div className="bd-example">
                            <Row className="orderHeaderInfos">
                                <Col className="col-5">
                                    <h2 className="card-title">Commande #{order.number}</h2>
                                    { getOrderStatusTag(order['status']) }
                                    <h5 className="card-title">
                                        <Link to={'/customers/dashboard/' + customer.id}>{customer['firstname']} {customer['lastname']} ({customer['orders'] ? customer['orders'].length : 0} commande(s))</Link>
                                    </h5>
                                    <h6 className="card-title">{customer['email']}</h6>
                                    <p className="card-text">Commande enregistrée le {dateConverter(order['createdAt'])}</p>
                                    { order['updatedAt'] && order['updatedAt'] !== order['createdAt'] &&
                                        <p className="card-text">Commande mise à jour le {dateConverter(order['updatedAt'])}</p>
                                    }
                                    <Row>
                                        <Col>
                                            <p>Source : {order['meta_vous_nous_avez_connu_comment']}</p>
                                            <p>Occasion : {order['meta_occasion']}</p>
                                        </Col>
                                        <Col>
                                            <p>Mini-Poêle : {order['meta_myam_Nb_Mini_Poele']}</p>
                                            <p>Kit Vaisselle : {order['meta_myam_Nb_Kit_Vaisselles']}</p>
                                        </Col>
                                    </Row>
                                    {order['meta_myam_Observations_Commande'] !== " " &&
                                        <Row>
                                            <Col>
                                                <p><strong>{getObservations(order)}</strong></p>
                                            </Col>
                                        </Row>
                                    }
                                    {order['customer_message'] &&
                                        <Row>
                                            <Col>
                                                <p><strong>{getNoteClient(order)}</strong></p>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                <Col>
                                    <Card className="mb-0">
                                        <Card.Header>
                                            Type de commande & date
                                        </Card.Header>
                                        <Card.Body>
                                            <h6 className="card-title">{order['meta_myam_Type_Expedition']}</h6>
                                            <p className="card-text" style={{'color':'black'}}><strong>{dateConverter(order['meta_myam_Date_Livraison_Ou_Retrait'], true)}</strong></p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="mb-0">
                                        <Card.Header>
                                            Adresse
                                        </Card.Header>
                                        <Card.Body>
                                            <h6 className="card-title">{order['shipping_first_name']} {order['shipping_last_name']}</h6>
                                            <h6 className="card-title">{order['shipping_address_1']} {order['shipping_address_2']}</h6>
                                            <h6 className="card-title">{order['shipping_postcode']} {order['shipping_city']}</h6>
                                            <h6 className="card-text">{order['billing_phone']}</h6>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div className="bd-example" style={{marginTop:"20px"}}>
                            <Row>
                                <div className="table-responsive">
                                    <table id="datatable" className="table" data-toggle="data-table">
                                        <thead>
                                        <tr>
                                            <th width="50%">Article</th>
                                            <th>Quantité</th>
                                            <th>Montant total (TTC)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.total} €</td>
                                                </tr>
                                        ))}
                                            <tr>
                                                <td align="right" colSpan="2"><strong>Sous total</strong></td>
                                                <td><strong>{order['grand_total']} €</strong></td>
                                            </tr>
                                            <tr>
                                                <td align="right" colSpan="2"><strong>Expédition</strong></td>
                                                <td><strong>{order['shipping_total']} €</strong></td>
                                            </tr>
                                            <tr>
                                                <td align="right" colSpan="2"><strong>Total de la commande</strong></td>
                                                <td><strong>{order['grand_total']} €</strong></td>
                                            </tr>
                                            <tr>
                                                <td align="right" colSpan="2"><strong>Observations</strong></td>
                                                <td><strong>{order['meta_myam_Observations_Commande']}</strong></td>
                                            </tr>
                                            <tr className={"totalsolde"}>
                                                <td align="right" colSpan="2"><strong>Solde</strong></td>
                                                <td><strong>{order['meta_myam_Solde']} €</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                            {order['meta__new_order_email_sent'] &&
                                <a target="_blank" rel="noreferrer" href={"https://myampaella.fr/wp-admin/post.php?post=" + order['number'] + "&action=edit"} className="btn btn-info">Voir sur WooCommerce</a>
                            }
                            &nbsp;
                            <Link className="btn btn-primary" to={"/orders/edit/" + order['number']}>Modifier la commande</Link>
                            &nbsp;
                            <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Supprimer la commande</Button>{' '}
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Supprimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention la commande sera supprimée définitivement. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à la commande
                    </Button>
                    <Button variant="primary" onClick={() => deleteOrder(order)}>
                        Je souhaite supprimer cette commande
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default OrderDashboard
