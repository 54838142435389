import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

// AOS
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'

import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

import {useSelector} from "react-redux";


// install Swiper modules
SwiperCore.use([Navigation]);

function calculateTodayOrders(orders) {

    // date du jour au format "YYYY-MM-DD"
    const isoToday = new Date().toISOString().slice(0, 10);

    // date du jour au format "d/m/Y"
    const frToday = new Date().toLocaleDateString();

    let nbNewOrders = 0;
    let nbOnSpotOrders = 0;
    let nbCookingOnSite = 0;
    let nbDelivery = 0;
    let todayAmount = 0;
    let todayNames = [];

    orders.forEach((item) => {

        // Commandes enregistrées aujourd'hui
        if(item['date_created'] && item['date_created'].substr(0,10) === isoToday) {
            nbNewOrders = parseInt(nbNewOrders) + 1;
            todayNames.push(item['billing_last_name']);
        }

        // Commande en livraison aujourd'hui
        if(item['meta_myam_Date_Livraison_Ou_Retrait'].substr(0,10) === frToday
            || item['meta_myam_Date_Livraison_Ou_Retrait'].substr(0,10) === isoToday) {
            switch(item['meta_myam_Type_Expedition']) {
                case 'Retrait':
                    nbOnSpotOrders = parseInt(nbOnSpotOrders) + 1;
                    break;
                case 'Livraison':
                    nbDelivery = parseInt(nbDelivery) + 1;
                    break;
                case 'Cuisson':
                    nbCookingOnSite = parseInt(nbCookingOnSite) + 1;
                    break;
                default:
                    break;
            }
            todayAmount = parseFloat(todayAmount) + parseFloat(item['grand_total']);
        }

    });

    return {
        'nbCookingOnSite' : nbCookingOnSite,
        'nbOnSpotOrders' : nbOnSpotOrders,
        'nbDeliveryOrders' : nbDelivery,
        'nbNewOrders' : nbNewOrders,
        'totalAmountToday' : todayAmount,
        'todayOrdersNames' : todayNames,
    }
}

const Index = (props) => {

    const [deliveryOrders, setDeliveryOrders] = useState(0);
    const [onSpotOrders, setOnSpotOrders] = useState(0);
    const [cookingOnSiteOrders, setCookingOnSiteOrders] = useState(0);
    const [newOrders, setNewOrders] = useState(0);
    const [totalAmountToday, setTotalAmountToday] = useState(0);
    const [todayOrderNames, setTodayOrdersNames] = useState([]);

    const orders = useSelector((state) => state.orders.orders);

    useEffect(() => {
        if(orders.length) {
            let ordersToday = calculateTodayOrders(orders);

            setCookingOnSiteOrders(ordersToday.nbCookingOnSite);
            setOnSpotOrders(ordersToday.nbOnSpotOrders);
            setDeliveryOrders(ordersToday.nbDeliveryOrders);
            setNewOrders(ordersToday.nbNewOrders);
            setTotalAmountToday(ordersToday.totalAmountToday);
            setTodayOrdersNames(ordersToday.todayOrdersNames);
        }

    }, [orders]);

        return (
            <>
                <Row className="mt-4">
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{deliveryOrders}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Commandes en livraison</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{onSpotOrders}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Commandes à emporter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                   <div>
                                       <h4 className="counter">{cookingOnSiteOrders}</h4>
                                   </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Cuisson sur place</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <span style={{color:'black'}} className="mb-2">Total de la journée</span>
                                    </div>
                                    <div className="progress-detail">
                                        <h4 className="counter">{totalAmountToday} €</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row-cols-2">
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{newOrders}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Nouvelles commandes</span>
                                    </div>
                                    <div>
                                        <ul>
                                            {
                                                todayOrderNames.map((name,index) => (
                                                    <li key={index} style={{color:'black'}}>{name}</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <Link to="/orders/new" style={{height:'100%'}} className="btn btn-primary -pull-right">Ajouter une commande</Link>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

export default Index
