import {configureStore, createSlice} from "@reduxjs/toolkit";

const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        orders: [],
    },
    reducers: {
        setOrders: (state, { payload }) => {
            state.orders = payload;

            console.log('REDUX set orders');
            console.log(state.orders);
        },
        addOrder: (state, { payload }) => {

            console.log('REDUX add order');
            console.log(payload);

            state.orders.push(payload);
        },
        updateOrder: (state, { payload }) => {
            let order = state.orders.find(item => String(item.id) === String(payload.id))
            if(order) {
                order = payload;
                var id = order.id;
                // On retirer la commande du store
                state.orders = state.orders.filter(item => item.id !== id)
                // On ajoute la nouvelle
                state.orders.push(payload);
            }
            else {
                console.log('REDUX error cant find order in store');
            }

        },
        removeOrder: (state, { payload }) => {
            const id = payload.id;
            state.orders = state.orders.filter(item => item.id !== id)
        }
    }
});

const customersSlice = createSlice({
    name: "customers",
    initialState: {
        customers: [],
    },
    reducers: {
        setCustomers: (state, { payload }) => {
            state.customers = payload;
            console.log('REDUX set customers');
            console.log(state.customers);
        }
    }
});

const loggedUserSlice = createSlice({
    name: "loggedUser",
    initialState: {
        loggedUser: null,
    },
    reducers: {
        setLoggedUser: (state, { payload }) => {
            state.loggedUser = payload;
            window.localStorage.setItem('loggedUser', payload);
        },
        clearLoggedUser: (state, { payload }) => {
            state.loggedUser = null;
            window.localStorage.removeItem('loggedUser');
            window.localStorage.removeItem('accessToken');
            window.localStorage.removeItem('refreshToken');
        },
    }
});

export const { setOrders, addOrder, updateOrder, removeOrder } = ordersSlice.actions;
export const { setLoggedUser, clearLoggedUser } = loggedUserSlice.actions;
export const { setCustomers } = customersSlice.actions;

export default configureStore({
    reducer: {
        orders: ordersSlice.reducer,
        loggedUser: loggedUserSlice.reducer,
        customers: customersSlice.reducer,
    }
})
