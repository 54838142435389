import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import { addOrder } from "../../store/redux";
import {getProductsRequest, addOrderRequest} from "../../requests/api";

const AddOrder = (props) => {

    const [newCustomerlastname, setNewcustomerlastname] = useState("");
    const [newCustomerfirstname, setNewcustomerfirstname] = useState("");
    const [newCustomeremail, setNewcustomeremail] = useState("");
    const [newBillinglastname, setNewbillinglastname] = useState("");
    const [newBillingfirstname, setNewbillingfirstname] = useState("");
    const [newBillingaddress, setNewbillingaddress] = useState("");
    const [newBillingzipcode, setNewbillingzipcode] = useState("");
    const [newBillingphone, setNewbillingphone] = useState("");
    const [newBillingcity, setNewbillingcity] = useState("");
    const [newState, setNewState] = useState("");
    const [newShippinglastname, setNewshippinglastname] = useState("");
    const [newShippingfirstname, setNewshippingfirstname] = useState("");
    const [newShippingaddress, setNewshippingaddress] = useState("");
    const [newShippingaddress2] = useState("");
    const [newShippingzipcode, setNewshippingzipcode] = useState("");
    const [newShippingcity, setNewshippingcity] = useState("");
    const [newBillingCompany, setNewBillingCompany] = useState("");
    const [newShippingCompany, setNewShippingCompany] = useState("");
    const [newKitvaisselle, setNewkitvaisselle] = useState("");
    const [newMinipoele, setNewminipoele] = useState("");
    const [newShippingcost, setNewshippingcost] = useState("");
    const [newObservations, setNewobservations] = useState("");
    const [newAcompte, setNewacompte] = useState("");
    const [newSolde, setNewsolde] = useState("");
    const [newEmpreinte, setNewEmpreinte] = useState("");
    const [newSmsavis, setNewSmsavis] = useState("");
    const [newNoteClient, setNewNoteClient] = useState("");
    const [newEvenement, setNewevenement] = useState("");
    const [newSource, setNewsource] = useState("");
    const [newMateriel, setNewmateriel] = useState("");
    const [newRetourmateriel, setNewretourmateriel] = useState("");
    const [newDateandhour, setNewdateandhour] = useState("");
    const [newExpeditionType, setExpeditionType] = useState("");
    const [newGrandTotal, setGrandTotal] = useState(0);
    const [catalogueProducts, setCatalogueProducts] = useState([]);
    const dispatch = useDispatch();
    const [items, setItems] = useState([{id:null, order:null, name: "", quantity: 0, total: 0}]);

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    let history =useHistory()

    useEffect(() => {

        const getCatalogueProducts = async () => {

            const data = await getProductsRequest();
            setCatalogueProducts(data.map((item) => ({...item, id: item.id })));
        }

        getCatalogueProducts();

    }, [])

    const addItem = async () => {
        setItems([...items, { id: null, product:null, order: null, name: '', quantity: 0, pu:0, total: 0}]);
    }

    let handleChange = (i, e) => {
        let newItemValues = [...items];
        newItemValues[i][e.target.name] = e.target.value;
        let unitPrice = newItemValues[i]['pu'] ? newItemValues[i]['pu'].replace(',','.') : 0;
        newItemValues[i]['total'] = (parseInt(newItemValues[i]['quantity']) * parseFloat(unitPrice)).toFixed(2);
        setItems(newItemValues);
        calculGrandTotal();
    }

    let copyCustomerDataToBillingForm = () => {
        setNewbillingfirstname(newCustomerfirstname);
        setNewbillinglastname(newCustomerlastname);
    }

    let copyBillingDataToShippingForm = () => {
        setNewshippingfirstname(newBillingfirstname);
        setNewshippinglastname(newBillinglastname);
        setNewShippingCompany(newBillingCompany);
        setNewshippingaddress(newBillingaddress);
        setNewshippingzipcode(newBillingzipcode);
        setNewshippingcity(newBillingcity);
    }

    let handleProductChange = (i, e) => {
        let newItemValues = [...items];

        newItemValues[i]['product'] = parseInt(e.target.value);
        newItemValues[i]['id'] = parseInt(e.target.value);

        var selectedIndex = e.target.selectedIndex;
        newItemValues[i]['name'] = e.target[selectedIndex].text;

        setItems(newItemValues);
        calculGrandTotal();
    }

    let calculGrandTotal = () => {

        var grandtotal = 0;
        items.forEach((element, index)=>{
            grandtotal = parseInt(grandtotal) + ( parseFloat(String(element.pu).replace(',','.')) * parseInt(element.quantity) );
        })

        setGrandTotal(parseFloat(grandtotal).toFixed(2));
        setNewsolde(parseFloat(grandtotal).toFixed(2));
    }

    const cancelOrder = () => {

        history.push('/orders');

    }

    function getNbOfProduct(searchStr) {

        let nb = 0;
        items.forEach((item, index)=>{
            if(item.name.toLowerCase().indexOf(searchStr) !== -1) {
                nb = parseInt(nb) + parseInt(item.quantity);
            }
        })
        return nb;
    }

    const saveOrder = async () => {

        if(newGrandTotal === "NaN") {
            setShowError(true);
            setErrorMessage('Le montant total de la commande est incorrect');
            return false;
        }

        const orderObject = {
            order: {
                customerFirstName: newCustomerfirstname,
                customerLastName: newCustomerlastname,
                customerEmail: newCustomeremail,
                billingAddress: newBillingaddress,
                billingCity: newBillingcity,
                billingCompany: newBillingCompany,
                billingFirstName: newBillingfirstname,
                billingLastName: newBillinglastname,
                billingPostcode: newBillingzipcode,
                billingPhone: newBillingphone,
                dateCreated: new Date().toISOString(),
                grandTotal: newGrandTotal ? parseFloat(newGrandTotal) : 0,
                metaDateDeLivraison: newDateandhour,
                metaMyamAcompte: newAcompte ? parseFloat(newAcompte) : 0,
                metaMyamDateLivraisonOuRetrait: newDateandhour,
                metaMyamDateRetrait: newDateandhour,
                metaMyamForfaitLivraison: newShippingcost ? parseFloat(newShippingcost) : 0,
                metaMyamMateriel: newMateriel,
                metaMyamMontantTotal: newGrandTotal ? parseFloat(newGrandTotal) : 0,
                metaMyamNbKitVaisselles: newKitvaisselle ? parseInt(newKitvaisselle) : 0,
                metaMyamNbMiniPoele: newMinipoele ? parseInt(newMinipoele) : 0,
                metaMyamNbPartRoyales: getNbOfProduct('oyale'),
                metaMyamNbPartSansCrustaces: getNbOfProduct('lla sans crust'),
                metaMyamNbPartVegetaliennes: getNbOfProduct('gane'),
                metaMyamNbPartVegetariennes: getNbOfProduct('tarienne'),
                metaMyamNbPartFetes: getNbOfProduct('ciale f'),
                metaMyamObservationsCommande: newObservations,
                metaMyamRemise: parseFloat(0),
                metaMyamRetourMateriel: newRetourmateriel,
                metaMyamSolde: newSolde ? parseFloat(newSolde) : 0,
                metaMyamTypeEvenement: newEvenement,
                metaMyamTypeExpedition: newExpeditionType,
                metaOccasion: newEvenement,
                metaOrderDeliveryTimestamp: newDateandhour,
                orderDeliveryDate: newDateandhour,
                metaVousNousAvezConnuComment: newSource,
                shippingAddress: newShippingaddress,
                shippingAddress2: newShippingaddress2,
                shippingCity: newShippingcity,
                shippingCompany: newShippingCompany,
                shippingCountry: null,
                shippingFirstName: newShippingfirstname,
                shippingLastName: newShippinglastname,
                shippingPostcode: newShippingzipcode,
                shippingState: null,
                shippingTotal: newShippingcost ? parseFloat(newShippingcost) : 0,
                status: newState,
                empreinte: newEmpreinte,
                smsavisclient: newSmsavis,
                customerMessage: newNoteClient
            },
            'order_items': []
        };


        // Articles de la commande

        if(items.length > 0 && items[0].id !== null && items[0].quantity !== 0) {
            items.forEach((element, index) => {

                if(typeof(element.pu) === "string") {
                    element.pu = element.pu.replace(',', '.');
                }

                if(typeof(element.total) === "string") {
                    element.total = element.total.replace(',', '.');
                }

                let newItem = {
                    "name": element.name,
                    "quantity": parseInt(element.quantity),
                    "total": parseFloat(element.total),
                    "productId": parseInt(element.product),
                    "unitPrice": parseFloat(element.pu)
                }

                orderObject['order_items'].push(newItem);
            })
        }
        else {
            setShowError(true);
            setErrorMessage('Veuillez séléctionner au moins un article');
            return false;
        }

        addOrderRequest(orderObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    // TODO : A vérifier
                    dispatch(addOrder(response));

                    // SUCCESS
                    history.push('/orders/dashboard/' + response.number);

                }
            });

    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Client</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customerlastname">Nom</Form.Label>
                                                <Form.Control type="text"  id="customerlastname" onChange={(event) => {
                                                    setNewcustomerlastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customerfirstname">Prénom</Form.Label>
                                                <Form.Control type="text"  id="customerfirstname" onChange={(event) => {
                                                    setNewcustomerfirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="customeremail">Adresse email</Form.Label>
                                                <Form.Control type="email"  id="customeremail" onChange={(event) => {
                                                    setNewcustomeremail(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Facturation</h4>
                                </div>
                                <Button className={'pull-right'} size="sm" variant="light" onClick={() => copyCustomerDataToBillingForm()}>Copier depuis les informations du client</Button>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billinglastname">Nom</Form.Label>
                                                <Form.Control type="text"  value={newBillinglastname} id="billinglastname" onChange={(event) => {
                                                    setNewbillinglastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingfirstname">Prénom</Form.Label>
                                                <Form.Control type="text" value={newBillingfirstname} id="billingfirstname" onChange={(event) => {
                                                    setNewbillingfirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingcompany">Entreprise</Form.Label>
                                                <Form.Control type="text"  id="billingcompany" onChange={(event) => {
                                                    setNewBillingCompany(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingaddress">Adresse</Form.Label>
                                                <Form.Control type="text"  id="billingaddress" onChange={(event) => {
                                                    setNewbillingaddress(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingzipcode">Code postal</Form.Label>
                                                <Form.Control type="text"  id="billingzipcode" onChange={(event) => {
                                                    setNewbillingzipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingcity">Ville</Form.Label>
                                                <Form.Control type="text"  id="billingcity" onChange={(event) => {
                                                    setNewbillingcity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="billingphone">Téléphone</Form.Label>
                                                <Form.Control type="text"  id="billingphone" onChange={(event) => {
                                                    setNewbillingphone(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Expedition</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Livraison"  type="radio" name="expedition" id="expedition1" onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition1">
                                                            Livraison à domicile
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Retrait" type="radio" name="expedition" id="expedition2" onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition2">
                                                            Retrait magasin
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                                <Col>
                                                    <Form.Check className="d-block">
                                                        <Form.Check.Input className="me-2" value="Cuisson-sur-place" type="radio" name="expedition" id="expedition3" onChange={(event) => {
                                                            setExpeditionType(event.target.value);
                                                        }} />
                                                        <Form.Check.Label  htmlFor="expedition3">
                                                            Cuisson sur place
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"20px"}}>
                                    <Col>
                                        <Form.Group className="form-group">
                                            <Form.Label  htmlFor="dateandhour">Date et heure</Form.Label>
                                            <Form.Control type="datetime-local"  id="dateandhour" onChange={(event) => {
                                                setNewdateandhour(event.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Adresse livraison</h4>
                                </div>
                                <Button className={'pull-right'} size="sm" variant="light" onClick={() => copyBillingDataToShippingForm()}>Copier depuis les informations de facturation</Button>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippinglastname">Nom</Form.Label>
                                                <Form.Control type="text" value={newShippinglastname} id="shippinglastname" onChange={(event) => {
                                                    setNewshippinglastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingfirstname">Prénom</Form.Label>
                                                <Form.Control type="text" value={newShippingfirstname} id="shippingfirstname" onChange={(event) => {
                                                    setNewshippingfirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcompany">Entreprise</Form.Label>
                                                <Form.Control type="text" value={newShippingCompany} id="shippingcompany" onChange={(event) => {
                                                    setNewShippingCompany(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingaddress">Adresse</Form.Label>
                                                <Form.Control type="text" value={newShippingaddress} id="shippingaddress" onChange={(event) => {
                                                    setNewshippingaddress(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingzipcode">Code postal</Form.Label>
                                                <Form.Control type="text" value={newShippingzipcode} id="shippingzipcode" onChange={(event) => {
                                                    setNewshippingzipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcity">Ville</Form.Label>
                                                <Form.Control type="text" value={newShippingcity} id="shippingcity" onChange={(event) => {
                                                    setNewshippingcity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Articles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form id="orderitems">
                                    {items.map((element,index) => (
                                        <Row key={"item" + index}>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="name">Designation</Form.Label>
                                                    <select className="form-select" id="id" name="id" onChange={e => handleProductChange(index, e)}>
                                                        <option key={""} value="0">Sélctionner un produit</option>
                                                    {catalogueProducts.map((product,productindex) => (
                                                        <option key={"product" + index + 'p' + productindex} value={product.id}>{product.title}</option>
                                                    ))}
                                                    </select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="quantity">Quantité</Form.Label>
                                                    <Form.Control type="integer"  id="quantity" name="quantity" value={element.quantity || ""} onChange={e => handleChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="pu">Prix unitaire TTC</Form.Label>
                                                    <Form.Control type="text"  id="pu" name="pu" value={element.pu || ""} onChange={e => handleChange(index, e)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    ))}
                                </Form>
                                <Button type="button" variant="light" onClick={() => addItem()}>Ajouter un autre article</Button>
                                <h3 style={{marginTop:"30px"}}>Total TTC de la commande : <span>{newGrandTotal} €</span></h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="status">Statut</Form.Label>
                                                <select className="form-select" id="status" onChange={(event) => {
                                                    setNewState(event.target.value);
                                                }}
                                                >
                                                    <option>Séléctionner un statut</option>
                                                    <option value="processing">En cours</option>
                                                    <option value="completed">Terminée</option>
                                                    <option value="cancelled">Annulée</option>
                                                    <option value="refunded">Remboursée</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Observations</Form.Label>
                                                <Form.Control type="text"  id="observations" onChange={(event) => {
                                                    setNewobservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="shippingcost">Frais de livraison</Form.Label>
                                                <Form.Control type="text"  id="shippingcost" onChange={(event) => {
                                                    setNewshippingcost(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="acompte">Acompte</Form.Label>
                                                <Form.Control type="text"  id="acompte" onChange={(event) => {
                                                    setNewacompte(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="solde">Solde</Form.Label>
                                                <Form.Control type="text" value={newSolde} id="solde" onChange={(event) => {
                                                    setNewsolde(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="solde">Empreinte</Form.Label>
                                                <Form.Control type="text" id="empreinte" onChange={(event) => {
                                                    setNewEmpreinte(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="kitvaisselle">Nb kit vaisselle</Form.Label>
                                                <Form.Control type="number"  id="kitvaisselle" onChange={(event) => {
                                                    setNewkitvaisselle(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="minipoele">Nb mini poêle</Form.Label>
                                                <Form.Control type="number"  id="minipoele" onChange={(event) => {
                                                    setNewminipoele(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="evenement">Occasion/Evénement</Form.Label>
                                                <Form.Control type="text"  id="evenement" onChange={(event) => {
                                                    setNewevenement(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="source">Source</Form.Label>
                                                <Form.Control type="text"  id="source" onChange={(event) => {
                                                    setNewsource(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="materiel">Matériel</Form.Label>
                                                <Form.Control type="text"  id="materiel" onChange={(event) => {
                                                    setNewmateriel(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="retourmateriel">Retour matériel</Form.Label>
                                                <Form.Control type="text"  id="retourmateriel" onChange={(event) => {
                                                    setNewretourmateriel(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={"3"}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="smsavis">SMS avis client</Form.Label>
                                                <Form.Control type="text" id="smsavis" value={newSmsavis} onChange={(event) => {
                                                    setNewSmsavis(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={"9"}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="noteclient">Note client</Form.Label>
                                                <Form.Control type="text" id="noteclient" value={newNoteClient} onChange={(event) => {
                                                    setNewNoteClient(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveOrder}>Enregistrer</Button>
                    </Col>
                </Row>
            </div>

            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de la commande ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition de la commande
                    </Button>
                    <Button variant="primary" onClick={cancelOrder}>
                        Je souhaite abandonner la commande en cours
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddOrder
