import { setAuthTokens, clearAuthTokens } from 'axios-jwt'
import axios from 'axios';
import jwt_decode from "jwt-decode";

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken;
}

function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
}

const BASE_URL = process.env.REACT_APP_API_URL;

// 1. Create an axios axiosInstance that you wish to apply the interceptor to
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

// Interceptor AXIOS qui permet de set le token en header à chaque requetes
axiosInstance.interceptors.request.use(
    (config) => {
        const token = "Bearer " + getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor AXIOS qui permet de gérer les 401 , token expiré & refresh token
axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await refreshToken();
                    const jwtToken = rs.data.token;
                    window.localStorage.setItem("accessToken", jwtToken);

                    const token = "Bearer " + jwtToken;
                    if (token) {
                        axiosInstance.defaults.headers.common["Authorization"] = token;
                    };

                    return axiosInstance(originalConfig);
                } catch (_error) {

                    if (_error.response.status === 401 && originalConfig._retry) {

                        // Logout
                        logoutRequest();
                        window.location.href = '/auth/sign-in';
                        return false;
                        //return Promise.reject(_error.response.data);
                    }

                    else if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }

                    return Promise.reject(_error);
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

function refreshToken() {
    return axios.post(BASE_URL + "auth/refresh_token", {
        refresh_token: getLocalRefreshToken(),
    });
}

// 4. Logging in
const loginRequest = async (params) => {

    const response = await axios.post(BASE_URL + 'auth/login', params)

    const accessToken = response.data.token;
    const refreshToken = response.data.refresh_token;
    const decoded = jwt_decode(accessToken);

    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("loggedUser", decoded.username);

    // save tokens to storage
    setAuthTokens({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        username: decoded.username
    })
}

// 5. Logging out
const logoutRequest = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("loggedUser");
    clearAuthTokens();
}

// Récupération des commandes
const getOrdersRequest = async (params) => {

    return axiosInstance.get('orders?order[order_delivery_date]=desc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération d'une commande particulière à partir de son numéro
const getOrderByNumberRequest = async (number) => {

    return axiosInstance.get('orders?number=' + number)
        .then(function(response) {
            const data = response.data['hydra:member'];
            if(data[0]) {
                return data[0];
            }
        })
}


// Récupération des commandes d'une date précise
const getOrdersByDateRequest = async (from, to) => {

    return axiosInstance.get('orders?order_delivery_date[after]=' + from + '&order_delivery_date[before]=' + to + '&order[order_delivery_date]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}


// Récupération des items d'une commande
const getOrderItemsRequest = async (orderid) => {

    return axiosInstance.get('order_items?orderObj=' + orderid)
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des commandes d'un client
const getCustomerOrdersRequest = async (customerid) => {

    return axiosInstance.get('orders?customer=' + customerid + '&order[order_delivery_date]=desc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des infos d'un client
const getCustomerRequest = async (id) => {

    let route = 'customers/' + id;

    if(String(id).search('customers/') !== -1) {
        route = id;
    }

    return axiosInstance.get(route)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Récupération des clients
const getCustomersRequest = async (params) => {

    return axiosInstance.get('customers')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des produits
const getProductsRequest = async (params) => {

    return axiosInstance.get('products')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des config
const getConfigRequest = async (params) => {

    return axiosInstance.get('configs')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter une commande
const addOrderRequest = async (data) => {

    return axiosInstance.post('orders/full', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}


// Mettre à jour une commande
const updateOrderRequest = async (data) => {

    return axiosInstance.put('orders/update', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Supprimer une commande une commande
const deleteOrderRequest = async (orderID) => {

    return axiosInstance.delete('orders/' + orderID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}


export { getOrdersByDateRequest, getCustomerOrdersRequest, getCustomersRequest, deleteOrderRequest, updateOrderRequest, addOrderRequest, getLocalAccessToken, loginRequest, logoutRequest, getProductsRequest, getOrderByNumberRequest, getOrderItemsRequest, getCustomerRequest, getOrdersRequest, getConfigRequest };
