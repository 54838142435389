import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import {Row, Col} from 'react-bootstrap'
import Card from '../../../components/Card'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from "@fullcalendar/list"
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "@fullcalendar/timegrid/main.css";
import {useSelector} from "react-redux";

function getBackgroundColorFromOrderType(type) {

    var color = 'grey';

    switch(type) {
        case 'Retrait':
            color = "orange";
            break;
        case 'Livraison':
            color = "#0d6efd";
            break;
        case 'Cuisson-sur-place':
            color = "purple";
            break;
        default:
            color = "grey";
    }

    return color;
}

function getBorderColorFromOrderType(type) {
    return getBackgroundColorFromOrderType(type);
}

function getTextColorFromOrderType(type) {
    var color = 'black';
    return color;
}

const Calender = () => {

    const [planningOrders, setPlanningOrders] = useState([]);
    const orders = useSelector((state) => state.orders.orders);

    useEffect(() => {

        if(orders.length) {
            setPlanningOrders(orders.map((item) => (
                {
                    id: item.number,
                    classNames: [item.status,item['meta_myam_Type_Expedition']],
                    title: '[' + String(item['meta_myam_Type_Expedition']).substr(0,1) + '] ' + String(item.billing_last_name).toUpperCase() + ' (' + item.grand_total + '€) ',
                    hour: item.order_delivery_date.substr(11, 6),
                    start: item.order_delivery_date,
                    url: '/orders/dashboard/' + item.number,
                    backgroundColor: getBackgroundColorFromOrderType(item['meta_myam_Type_Expedition']),
                    borderColor: getBorderColorFromOrderType(item['meta_myam_Type_Expedition']),
                    textColor: getTextColorFromOrderType(item['meta_myam_Type_Expedition']),
                    total: 100,
                    nbparts: [
                        {
                            'Type': 'Royales',
                            'nb': item['meta_myam_Nb_Part_Royales']
                        },
                        {
                            'Type': 'Sans Crustaces',
                            'nb': item['meta_myam_Nb_Part_Sans_Crustaces']
                        },
                        {
                            'Type': 'Vegetaliennes',
                            'nb': item['meta_myam_Nb_Part_Vegetaliennes']
                        },
                        {
                            'Type': 'Vegetariennes',
                            'nb': item['meta_myam_Nb_Part_Vegetariennes']
                        },
                    ]
                })));
        }

    }, [orders])


    function renderEventContent(eventInfo) {

        return (
            <>
                <span>{eventInfo.timeText} - {eventInfo.event.title} (#{eventInfo.event.id})</span>
            </>
        )
    }

    return (
        <>
            <Row>
                <Col lg="12">
                    <Row>
                        <Col lg="12" className="col-lg-12">
                            <Card>
                                <Card.Body>
                                    <FullCalendar
                                        timeZone={'UTC'}
                                        locale={'fr'}
                                        firstDay={1}
                                        nowIndicator={true}
                                        buttonText={{
                                            today:    'aujourd\'hui',
                                            month:    'mois',
                                            week:     'semaine',
                                            day:      'jour',
                                            list:     'liste'
                                        }}
                                        plugins={[ dayGridPlugin,listPlugin,bootstrapPlugin,timeGridPlugin ]}
                                        // themeSystem = 'bootstrap'
                                        initialView={'timeGridWeek'}
                                        headerToolbar={{
                                            left:'prev,next today',
                                            center:'title',
                                            right:'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                                        }}
                                        eventContent={renderEventContent}
                                        scrollTime={'11:00:00'}
                                        events={planningOrders}
                                        eventMinHeight={25}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Calender
